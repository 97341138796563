<template>
  <p>Redirect...</p>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["token"]),
  },
  created() {
    this.handleLogout();
  },
  methods: {
    handleLogout() {
      const self = this;

      self.$store
        .dispatch("apis/get", {
          url: "auth/logout",
          params: {
            refreshToken: self.token.refreshToken,
          },
        })
        .then((response) => {
          localStorage.removeItem("vuex");

          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          }

          window.location.reload();
        });
    },
  },
};
</script>
